import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import {
  Paper,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
} from "@mui/material";
import { SERVER } from "../utils";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

const steps = [
  {
    label: "Introduction",
    description:
      "Hey, welcome! My name is Darren and in this video, I'll be sharing the Pristine Clarity Blueprint for Entrepreneurs. This four-step framework, backed by neuroscience, will help you unlock pristine clarity on your purpose and goals as an entrepreneur. By following this blueprint, you'll be able to start building momentum towards having a passion-driven business that gives you complete control over your income. I'll also share my personal journey and how I've successfully helped over a dozen people make money online. Get ready to take action and transform your entrepreneurial journey!",
  },
  {
    label: "Define Your Massively Transformative Purpose",
    description:
      "In this video, I discuss the process of finding your massively transformative purpose. I cover three key aspects: gaining awareness of your strengths, understanding and cultivating passion, and merging passion with purpose. I share my personal experience with taking a strengths assessment and how it helped me discover my fit for coaching. I emphasize the importance of gaining awareness of our strengths and passions, as well as the role of passion in focused attention and flow states. Finally, I provide steps for identifying and cultivating passion, and merging it with a purpose that addresses significant challenges in a good market.",
  },
  {
    label: "Create Your Goal Stack",
    description:
      "In this video, I will explain the concept of goal stacking and how it can bring clarity and organization to your life. Goal stacking involves breaking down your long-term goals into smaller, more manageable ones, starting with a massively transformative purpose. By setting clear goals, you can eliminate uncertainty and avoid getting stuck in analysis paralysis. I will also introduce the concept of S.M.A.R.T. goals and demonstrate how to create a goal stack using the Clarity app. Join me in this video to learn how goal stacking can help you achieve your objectives and maintain focus.",
  },
  {
    label: "Optimize Your Calendar for Flow",
    description:
      "In this video, I will guide you through the last two steps of the Pristine Clarity Blueprint: time blocking your daily goals and unlocking cognitive surplus. By optimizing your calendar for flow and reducing cognitive load, you can free up mental space and energy to focus on your purpose. I will explain the importance of using a calendar, the concept of Parkinson's Law, and how to enforce time constraints for increased productivity. Additionally, I will share steps for optimizing your calendar for flow, including setting recurring events, implementing a power down ritual, and scheduling weekly strategy sessions. Join me in this video to take action, build momentum, and gain a competitive advantage with pristine clarity.",
  },
];

const Blueprint = () => {
  const { user } = useAuthenticator();
  const [activeStep, setActiveStep] = useState(0);
  const userEmail = user.attributes.email;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let url = `${SERVER}/google/authorize/?email=${userEmail}&timezone=${timezone}`;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const videoURLs = [
    "https://screenpal.com/player/c0jncBVka7Q?&ff=1&title=0&controls=1",
    "https://screenpal.com/player/c0jncEVka7m?&ff=1&title=0&controls=1",
    "https://screenpal.com/player/c0jnVXVkaMC?&ff=1&title=0&controls=1",
    "https://screenpal.com/player/c0jnc8Vkas4?&ff=1&title=0&controls=1",
  ];

  const handleClick = () => {
    
  }
  return (
    <NavBar title="The Pristine Clarity Blueprint For Entrepreneurs">
      <Paper sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 3 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                  variant="h6"
                >
                  Watch This Video:
                </Typography>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "300px",
                    height: "0",
                  }}
                >
                  <iframe
                    title="Walkthrough"
                    src={videoURLs[index]}
                    frameBorder="0"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      maxWidth: "640px",
                      maxHeight: "300px",
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>
                <Typography style={{ marginTop: "10px" }} variant="h6">
                  Summary
                </Typography>
                <Typography
                  style={{ margin: "10px 0px 10px 0px", maxWidth: "640px" }}
                >
                  {step.description}
                </Typography>
                <Typography style={{ marginTop: "10px" }} variant="h6">
                  Next Steps:
                </Typography>
                <div style={{ maxWidth: "640px" }}>
                  {index === 0 && (
                    <ol>
                      <li>
                        <a
                          style={{ fontWeight: "bold" }}
                          href="https://drive.google.com/file/d/1TEo7MFMyzEkcpZ1h7E_OfHP7WPth6a69/view?usp=sharing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>{" "}
                        the slides
                      </li>
                    </ol>
                  )}
                  {index === 1 && (
                    <ol>
                      <li>Gain Awareness of Your Strengths</li>
                      <ol type="a">
                        <li>
                          Take a strengths assessment and note patterns of how
                          you use your strengths in your daily life. I recommend
                          the{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.gallup.com/cliftonstrengths/en/252137/home.aspx"
                          >
                            Clifton Strengths assessment
                          </a>
                          ; however, if you want to take a free assessment
                          instead then consider the{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://high5test.com/"
                          >
                            High Five Test
                          </a>
                          .{" "}
                        </li>
                      </ol>
                      <li>
                        Understand Your Passions or Morphe Your Curiosities Into
                        Passion
                        <ol type="a">
                          <li>
                            Make a list of 25 things you're curious about. The
                            more specific, the better.
                          </li>
                          <li>
                            Hunt for intersections beacuse passion is simply the
                            intersection of multiple curiosites according to
                            neuroscience. Draw a line between curiousities that
                            could relate to each other and write a question on
                            the line that connects the two curiousities.
                          </li>
                          <li>
                            If you can clearly see areas that you know you're
                            passionate about, then you can continue to the next
                            module; otherwise, morphe your curiosites into
                            passion by playing in the intersections of your
                            curiosites for 10 to 20 minutes a day by feeding
                            your curiosities through videos, books, podcasts,
                            articles, and experts. Journal your insights each
                            day and go public with your ideas to get feedback
                            and experience public wins.
                          </li>
                        </ol>
                      </li>
                      <li>Merge Passion With Purpose</li>
                      <ol type="a">
                        <li>
                          List out 15 massive problems that you would love to
                          see solved -- or problems that make you angry.
                        </li>
                        <li>
                          Look for places where your passions intersect with
                          these problems
                        </li>
                        <li>
                          Write a Massive Transformative Purpose in one
                          sentence. <br />
                          Use this tempalte to start: "To help (the group you
                          want to be a hero for) to (result they want to
                          acheive) through (your solution)"
                        </li>
                        <li>Double Check It.</li>
                        <ol type="i">
                          <li>
                            Is it audaciously big and aspirational? (you should
                            probably 10x it)
                          </li>
                          <li>
                            Can it cause significant transformation to an
                            individual, industry, community, or to the planet?
                          </li>
                          <li>
                            Is there a clear why behind it that you're
                            passionate about? Something that unites and inspired
                            action?
                          </li>
                          <li>Are your ideal customers in massive pain?</li>
                          <li>Are they easy to target?</li>
                          <li>Do they have have purchasing power?</li>
                          <li>
                            Is there are going to be more of them over time?
                          </li>
                        </ol>
                      </ol>
                    </ol>
                  )}
                  {index === 2 && (
                    <ol>
                      <li>
                        <a href={url}>Click here</a> to set up a two-way
                        integration between your goal stack and Google Calendar
                      </li>
                      <li>
                        Create your <Link to="/">Goal Stack</Link> from top to
                        bottom
                      </li>
                    </ol>
                  )}
                  {index === 3 && (
                    <div>
                      <ol>
                        <li>
                          Set all recurring events -- workouts, deep flow
                          blocks, learning sessions, family time, weekly
                          strategy sessions, and your power down ritual
                        </li>
                        <li>
                          Add agenda for your power down ritual to the notes of
                          the recurring events
                        </li>
                        <ol type="a">
                          <li>Review your weekly goals.</li>
                          <li>
                            Refine daily gaols for next day and schedule them.
                            (3 max)
                          </li>
                          <li>
                            Prep all tasks and subtasks for next day to
                            eliminate friction and procrastination.
                          </li>
                          <li>
                            Clear out undone tasks, tie lose ends, and process
                            email.
                          </li>
                        </ol>
                        <li>
                          Add agenda for your weekly strategy sessions to the
                          notes of the recurring events
                        </li>
                        <ol type="a">
                          <li>
                            Review your goal stack top to bottom and modify
                            goals accordingly.
                          </li>
                          <li>Assess weekly goals against monthly goals.</li>
                          <li>Set 1 to 3 weekly goals.</li>
                          <li>
                            Set 1 to 3 daily goals for each day of the week.
                          </li>
                          <li>Map all of it on you calendar.</li>
                        </ol>
                        <li>Add clear goals for all other recurring events.</li>
                      </ol>
                      <p>Click <a href="https://calendar.app.google/49GaRK18E6ovUXFf9" target="_blank" rel="noopener noreferrer">here</a> to apply for results coaching.</p>
                    </div>
                  )}
                </div>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? "Finish" : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button
              variant="outlined"
              onClick={handleReset}
              sx={{ mt: 1, mr: 1 }}
            >
              Reset
            </Button>
          </Paper>
        )}
      </Paper>
    </NavBar>
  );
};

export default Blueprint;
