import React, { useState, useEffect, useParams } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GoalStack from "./Components/GoalStack";
import { Amplify, Auth, Hub } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import { SERVER } from "./utils";
import Home from "./Components/Home";
import Blueprint from "./Components/Blueprint";
import TermsOfUse from "./Components/TermsOfUse";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import { Login } from "./Components/Login";
import { RequireAuth } from "./lib/RequireAuth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FAQ from "./Components/FAQ";

Amplify.configure(awsExports);

function App() {
  const [userId, setUserId] = useState();

  useEffect(() => {
    const handleAuthEvent = async () => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log("User has signed in:", user);
          // Register the user in your database
          axios
            .post(`${SERVER}/register/`, {
              email: user.attributes.email,
            })
            .then((response) => {
              setUserId(response.data.userId);
              console.log("User registered:", response.data);
            })
            .catch((error) => console.error("Error registering user:", error));
        })
        .catch((err) => {
          console.log("User is not signed in");
        });
    };

    handleAuthEvent();

    const authListener = ({ payload }) => {
      if (payload.event === "signIn" || payload.event === "signUp") {
        handleAuthEvent();
      }
    };

    const hubListenerCancelToken = Hub.listen("auth", authListener);

    return () => {
      hubListenerCancelToken();
    };
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Authenticator.Provider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home userId={userId} />}></Route>
            <Route
              path="/blueprint"
              element={
                <RequireAuth>
                  <Blueprint />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/faq"
              element={
                <RequireAuth>
                  <FAQ />
                </RequireAuth>
              }
            ></Route>
            <Route path="/login" element={<Login />} />
            <Route path='/terms' element={<TermsOfUse/>}/>
          <Route path='/privacy' element={<PrivacyPolicy/>}/>
          </Routes>
        </Router>
      </Authenticator.Provider>
    </LocalizationProvider>
  );
}

export default App;
