import "@aws-amplify/ui-react/styles.css";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import { Link, useLocation } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import logo from "../logo/ClarityLogoTransparent.png";

const NavBar = (props) => {
  //const [selectedIndex, setSelectedIndex] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  // I need to calculate the selectedIndex based off the pathname
  // I have the list of paths. I have the current path

  const handleListItemClick = (event, index) => {
    //setSelectedIndex(index);
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 240;

  const iconList = [
    <TableRowsOutlinedIcon />,
    <ConstructionOutlinedIcon />,
    <LiveHelpOutlinedIcon />,
  ];
  const paths = ["/", "/blueprint", "/faq"];

  const selectedIndex = paths.indexOf(pathname);

  const drawer = (
    <>
      <div>
        <Toolbar style={{ justifyContent: "center", paddingLeft: "16px" }}>
          {/* <div style={{width: "150px", height: "auto", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Logo style={{width: "150px", height: "auto"}}/>
          </div> */}
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={logo} alt="Clarity Logo" style={{width: "30px", height: "30px", marginRight: "10px"}}/>
          </div>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Clarity
          </Typography>
        </Toolbar>
        <Divider />
        <List>
          {["Goal Stack", "Blueprint", "FAQ"].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemButton component={Link} to={paths[index]}>
                <ListItemIcon>{iconList[index]}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton onClick={() => Auth.signOut()}>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </>
  );

  const container =
    props.window !== undefined ? () => props.window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ borderBottom: "1px solid lightgray" }}
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div"></Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        }}
      >
        <div
          style={{ width: "100%", height: "100vh", backgroundColor: "#f2f2f2" }}
        >
          <Toolbar />
          <Grid container direction={"column"}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                // height: "",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <div>
                <h1
                  style={{
                    borderTop: "2px solid #007FFF",
                    borderBottom: "2px solid #007FFF",
                    textAlign: "center",
                  }}
                >
                  {props.title}
                </h1>
                <h3>{props.subtitle}</h3>
                <h3>
                  {props.calendar_subtitle &&
                    props.calendar_subtitle.split("\n").map((item, i) => {
                      return (
                        <span key={i}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                </h3>
                <p>{props.text}</p>
              </div>
            </Grid>
            <Divider />
            <Grid xs={12} sm={12} md={12} lg={12}>
              <Grid
                style={{ backgroundColor: "#f2f2f2" }}
                container
                direction={"column"}
              >
                {props.children}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Box>
  );
};

export default NavBar;
