import {
  startOfWeek,
  endOfWeek,
  format,
  addDays,
  getISOWeek,
  getYear,
} from "date-fns";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";

//export const SERVER_IP = "52.4.42.42";
// export const SERVER = "http://localhost:8000";
export const SERVER = "https://server.useclarity.io"

export const formateTimeToHHMM = (date) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const generateWeeks = (month, year) => {
  const weeks = [];

  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0);

  // sets the current day to the start of the week that includes the current
  let currentDay = startOfWeek(firstDay, { weekStartsOn: 0 });

  while (currentDay <= lastDay) {
    const weekStart = currentDay;
    const weekEnd = endOfWeek(currentDay, { weekStartsOn: 0 });

    const weekNumber = getISOWeek(weekEnd);
    const weekYear = getYear(weekEnd);
    const weekLabel = `${weekNumber} (${format(
      weekStart,
      "MM/dd/yyyy"
    )} - ${format(weekEnd, "MM/dd/yyyy")})`;

    weeks.push({
      start: weekStart,
      end: weekEnd,
      label: weekLabel,
      number: weekNumber,
      year: weekYear,
    });

    currentDay = addDays(currentDay, 7);
  }

  return weeks;
};

// const formatDate = (date) => {
//   const month = String(date.getMonth() + 1).padStart(2, '0');
//   const day = String(date.getDate()).padStart(2, '0');
//   return `${month}/${day}`;
// };

export const generateWeekDays = (weekNumber, year) => {
  const weekStart = startOfWeek(new Date(year, 0, 1), { weekStartsOn: 0 });
  const weekStartDate = addDays(weekStart, (weekNumber - 1) * 7);

  const days = [];
  for (let i = 0; i < 7; i++) {
    const day = addDays(weekStartDate, i);
    const formattedDay = format(day, "EEEE MM/dd/yy");
    days.push({ label: formattedDay, value: format(day, "yyyy-MM-dd") });
  }

  return days;
};

export const get12PMDateObject = () => {
  let currentDate = new Date();
  currentDate.setHours(12);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return currentDate;
};

export const get1PMDateObject = () => {
  let currentDate = new Date();
  currentDate.setHours(13);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return currentDate;
};

export const checkForTimeError = (startTime, endTime) => {
  if (startTime !== null || endTime !== null) {
    if (startTime === null) {
      return true;
    }
    if (endTime === null) {
      return true;
    }
    if (startTime >= endTime) {
      return true;
    }
  }
  return false;
};
export const getPaddingLeft = (goalType) => {
  let paddingLeft = 0;
  if (goalType === "HHG") {
    paddingLeft = 20;
  } else if (goalType === "YG") {
    paddingLeft = 40;
  } else if (goalType === "QG") {
    paddingLeft = 60;
  } else if (goalType === "MG") {
    paddingLeft = 80;
  } else if (goalType === "WG") {
    paddingLeft = 100;
  } else if (goalType === "DG") {
    paddingLeft = 120;
  } else if (goalType === "S") {
    paddingLeft = 140;
  } else if (goalType === "SS") {
    paddingLeft = 160;
  }
  return paddingLeft;
};

let month_map = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

function getWeekDateString(weekNumber, year) {
  const weekStart = getWeekStartDate(weekNumber, year);
  const weekEnd = getWeekEndDate(weekNumber, year);
  const startMonthName = getMonthName(weekStart.getMonth());

  if (weekStart.getMonth() === weekEnd.getMonth()) {
    return `${startMonthName} ${weekStart.getDate()} - ${weekEnd.getDate()}, ${year}`;
  } else {
    return `${startMonthName} ${weekStart.getDate()} - ${getMonthName(
      weekEnd.getMonth()
    )} ${weekEnd.getDate()}, ${year}`;
  }
}

function getWeekStartDate(weekNumber, year) {
  const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  const dayOfWeek = date.getDay();
  const weekStart = new Date(date);
  weekStart.setDate(date.getDate() - dayOfWeek);
  return weekStart;
}

function getWeekEndDate(weekNumber, year) {
  const weekStart = getWeekStartDate(weekNumber, year);
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6);
  return weekEnd;
}

function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[month];
}

function getWeekdayName(day) {
  const weekdayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekdayNames[day];
}

function getFormattedDate(dateString) {
  const date = new Date(dateString);
  const utcDate = date.toISOString().split("T")[0]; // Get UTC date string
  const [year, month, day] = utcDate.split("-"); // Extract year, month, and day

  const weekday = getWeekdayName(date.getUTCDay()); // Use getUTCDay() for UTC-based day of the week
  const monthName = getMonthName(parseInt(month) - 1); // Adjust month index to zero-based

  return `${weekday} ${monthName} ${day}, ${year}`;
}

export const getScheduleDisplay = (goal) => {
  let display = "";
  if (goal.goal_type === "YG") {
    display = `for ${goal.year}`;
  } else if (goal.goal_type === "QG") {
    display = `for Q${goal.quarter} ${goal.year}`;
  } else if (goal.goal_type === "MG") {
    display = `for ${month_map[goal.month]} ${goal.year}`;
  } else if (goal.goal_type === "WG") {
    display = `for ${getWeekDateString(goal.week, goal.year)}`;
  } else if (goal.goal_type === "DG") {
    display = `for ${getFormattedDate(goal.day)}`;
  }
  return display;
};

export const generateMonths = (quarter) => {
  let months = [];
  if (quarter === 1) {
    months = ["January", "February", "March"];
  } else if (quarter === 2) {
    months = ["April", "May", "June"];
  } else if (quarter === 3) {
    months = ["July", "August", "September"];
  } else if (quarter === 4) {
    months = ["October", "November", "December"];
  }

  return months;
};
