import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import NavBar from "./NavBar";
import { useAuthenticator } from "@aws-amplify/ui-react";
import SalesPage from "./SalesPage";
import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoalStack from "./GoalStack";
import { Login } from "./Login";

const Home = ({ userId }) => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  return route === "authenticated" ? (
    <NavBar title="Goal Stack">
      {userId && (
        <GoalStack
          userId={userId}
        />
      )}
    </NavBar>
  ) : (
    <Login />
  );
};

//backgroundColor: "#f2f2f2"
export default Home;
