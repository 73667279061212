export const getWeekOfYear = (date) => {
    let tempDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    tempDate.setDate(tempDate.getDate() - tempDate.getDay() + (tempDate.getDay() === 0 ? -6:1));
    let jan4 = new Date(tempDate.getFullYear(), 0, 4);
    return Math.ceil((((tempDate - jan4) / 86400000) + jan4.getDay()+1)/7);
}

function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Remember to +1 for the month
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

export const getAllTimes = (date) => {
    let dateObject = new Date(date)
    let year = dateObject.getFullYear();

    // Quarter
    let quarter = Math.floor((dateObject.getMonth() + 3) / 3);

    // Month
    let month = dateObject.getMonth() + 1;  // JavaScript months are 0-based, so we add 1

    // Week
    // Note: JavaScript does not have a built-in function to get the week number.
    // You can use a function like the one I described in a previous message.
    let week = getWeekOfYear(dateObject);

    // Day
    // let day = dateObject.toISOString().split('T')[0];
    let day = formatDate(dateObject);
    let hours = dateObject.getHours().toString().padStart(2, '0');
    let minutes = dateObject.getMinutes().toString().padStart(2, '0');

    let time = `${hours}:${minutes}`;

    return {year: year, quarter: quarter, month: month, week: week, day: day, time: time}

}
