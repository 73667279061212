import React, { useEffect, useState } from "react";
import Goal from "./Goal";
import NavBar from "./NavBar";
import { SERVER, formatDateToYYYYMMDD, formateTimeToHHMM } from "../utils";
import {
  Button,
  Paper,
  Drawer,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getGoalType, findGoal, getWeeklyGoals } from "../lib/getGoals";
import axios from "axios";
import { Link } from "react-router-dom";

function GoalStack({ userId }) {
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [purposeText, setPurposeText] = useState(
    "To help (the group you want to be a hero for) to (result they want to achieve) through (your solution)"
  );
  const [hasGoogleCredentials, setHasGoogleCredentials] = useState(false);
  const [isOnboardingDialogOpen, setOnboardingDialogOpen] = useState(false);
  const [reauth, setReauth] = useState(false);
  const { user } = useAuthenticator();
  const userEmail = user.attributes.email;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let url = `${SERVER}/google/authorize/?email=${userEmail}&timezone=${timezone}`;

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const fetchGoals = (user_id) => {
    if (user_id) {
      fetch(`${SERVER}/api/goals/?userId=${user_id}`)
        .then((response) => response.json())
        .then((data) => {
          setGoals(data);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isDrawerOpen) {
      handleSavePurpose();
    }
  };

  const handleCheckboxClick = (event) => {
    setChecked(event.target.checked);
    axios
      .post(`${SERVER}/api/setonboarded/`, {
        user: userId,
        checked: event.target.checked,
      })
      .then((response) => {
        console.log("Checkbox value saved: ", response.data);
      })
      .catch((error) => {
        console.log("Error saving checkbox value: ", error);
      });
  };

  useEffect(() => {
    (async () => {
      // Create a new Date object for the current date and time
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      // Calculate the timestamp for 14 days ago (subtracting 14 days in milliseconds)
      const fourteenDaysAgoTimestamp =
        currentTimestamp - 14 * 24 * 60 * 60 * 1000;
      // Create a new Date object for 14 days ago using the calculated timestamp
      const fourteenDaysAgoDate = new Date(fourteenDaysAgoTimestamp);
      let startDate = fourteenDaysAgoDate.toISOString();
      const dailyGoals = await getGoalType("DG", userId);
      const filterDailyGoals = dailyGoals.filter((DG) => {
        const fourteenDaysAheadDate =
          currentTimestamp - 14 * 24 * 60 * 60 * 1000;
        const DGdate = new Date(DG[1]["day"] + "T" + DG[1]["time_start"]);
        if (DGdate <= fourteenDaysAheadDate && DGdate >= fourteenDaysAgoDate) {
          return true;
        } else {
          return false;
        }
      });

      fetch(`${SERVER}/api/google/check/?email=${userEmail}`)
        .then((response) => response.json())
        .then((data) => {
          setHasGoogleCredentials(data.has_credentials);
          if (data.has_credentials) {
            fetch(
              `${SERVER}/api/google/get_events/?email=${userEmail}&start_date=${startDate}`
            )
              .then((response) => response.json())
              .then((googleEvents) => {
                // loops through each of the daily goals, checks if the daily goal's google event ID is a key in the
                // dictionary of google event IDs retrieved. If it is, then it will check if there are changes and update the daily goal
                // if there are. If the google event ID doesn't exist in the dictionary, then it will delete the daily goal because
                // the related google event was deleted.
                if (googleEvents["reauthenticate"]) {
                  setReauth(true);
                } else {
                  dailyGoals.forEach((dailyGoal) => {
                    // checks to see if daily goal's google event ID is a key in the dictionary of retrieved google events
                    if (
                      dailyGoal[1]["google_event_id"] in googleEvents["events"]
                    ) {
                      const gEventID = dailyGoal[1]["google_event_id"];
                      console.log("found a daily goal with a google event");

                      // creates a Date object for the start of the daily goal
                      let dailyGoalStart = null;
                      if (dailyGoal[1]["time_start"] !== null) {
                        dailyGoalStart = new Date(
                          dailyGoal[1]["day"] + "T" + dailyGoal[1]["time_start"]
                        );
                      } else {
                        const dailyGoalStartDateParts =
                          dailyGoal[1]["day"].split("-");
                        dailyGoalStart = new Date(
                          parseInt(dailyGoalStartDateParts[0]),
                          parseInt(dailyGoalStartDateParts[1]) - 1,
                          parseInt(dailyGoalStartDateParts[2]),
                          0,
                          0,
                          0,
                          0
                        );
                      }
                      // creates a Date object for the end of the daily goal.
                      let dailyGoalEnd = null;
                      if (dailyGoal[1]["time_end"] !== null) {
                        dailyGoalEnd = new Date(
                          dailyGoal[1]["day"] + "T" + dailyGoal[1]["time_end"]
                        );
                      } else {
                        const dailyGoalEndDateParts =
                          dailyGoal[1]["day"].split("-");
                        dailyGoalEnd = new Date(
                          parseInt(dailyGoalEndDateParts[0]),
                          parseInt(dailyGoalEndDateParts[1]) - 1,
                          parseInt(dailyGoalEndDateParts[2]),
                          0,
                          0,
                          0,
                          0
                        );
                        dailyGoalEnd.setDate(dailyGoalEnd.getDate() + 1);
                      }
                      let gEventStart = null;
                      let gEventEnd = null;
                      let isAllDayEvent = false;
                      if (
                        "dateTime" in googleEvents["events"][gEventID]["start"]
                      ) {
                        gEventStart = new Date(
                          googleEvents["events"][gEventID]["start"]["dateTime"]
                        );
                        gEventEnd = new Date(
                          googleEvents["events"][gEventID]["end"]["dateTime"]
                        );
                      } else {
                        isAllDayEvent = true;
                        const startDateParts =
                          googleEvents["events"][gEventID]["start"][
                            "date"
                          ].split("-");
                        gEventStart = new Date(
                          parseInt(startDateParts[0]),
                          parseInt(startDateParts[1]) - 1,
                          parseInt(startDateParts[2]),
                          0,
                          0,
                          0,
                          0
                        );
                        const endDateParts =
                          googleEvents["events"][gEventID]["end"]["date"].split(
                            "-"
                          );
                        gEventEnd = new Date(
                          parseInt(endDateParts[0]),
                          parseInt(endDateParts[1]) - 1,
                          parseInt(endDateParts[2]),
                          0,
                          0,
                          0,
                          0
                        );
                      }
                      // I have the Date objects of the start and end times of the daily goals and google events
                      // if the google event is an all day event, then I want to save null values in my daily goal
                      const dailyGoalSummary = dailyGoal[1]["goal_text"];
                      let gEventSummary = null;
                      if ("summary" in googleEvents["events"][gEventID]) {
                        gEventSummary =
                          googleEvents["events"][gEventID]["summary"];
                      }
                      const dailyGoalDescription = dailyGoal[1]["notes"];
                      let gEventDescription = null;
                      if ("description" in googleEvents["events"][gEventID]) {
                        gEventDescription =
                          googleEvents["events"][gEventID]["description"];
                      }

                      if (
                        dailyGoalStart.getTime() !== gEventStart.getTime() ||
                        dailyGoalEnd.getTime() !== gEventEnd.getTime() ||
                        dailyGoalSummary !== gEventSummary ||
                        dailyGoalDescription !== gEventDescription
                      ) {
                        console.log("There was an update!");
                        if (
                          dailyGoalStart.getTime() !== gEventStart.getTime()
                        ) {
                          console.log("Difference in start times");
                          console.log(
                            "Daily Goal Start Time: ",
                            dailyGoalStart.getTime()
                          );
                          console.log(
                            "Google Event Start Time: ",
                            gEventStart.getTime()
                          );
                        }
                        if (dailyGoalEnd.getTime() !== gEventEnd.getTime()) {
                          console.log("Difference in end times");
                          console.log(
                            "Daily Goal Start Time: ",
                            dailyGoalStart.getTime()
                          );
                          console.log(
                            "Daily Goal End Time: ",
                            dailyGoalEnd.getTime()
                          );
                          console.log(
                            "Google Event End Time: ",
                            gEventEnd.getTime()
                          );
                        }
                        const updateData = {
                          goal_data: {
                            goal_id: dailyGoal[1].id,
                            goal_text: gEventSummary,
                            parent: dailyGoal[1].parent,
                            year: dailyGoal[1].year,
                            quarter: dailyGoal[1].quarter,
                            month: dailyGoal[1].month,
                            week: dailyGoal[1].week,
                            day: formatDateToYYYYMMDD(gEventStart),
                            time_start: isAllDayEvent
                              ? null
                              : formateTimeToHHMM(gEventStart),
                            time_end: isAllDayEvent
                              ? null
                              : formateTimeToHHMM(gEventEnd),
                            goal_type: dailyGoal[1].goal_type,
                            notes: gEventDescription,
                            user: userId,
                          },
                          hasGoogleCredentials: data.has_credentials,
                          timezone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        };
                        const url = `${SERVER}/api/goals/update/`;
                        axios
                          .post(url, updateData)
                          .then((response) => {
                            //Handle success response
                            console.log(
                              "Goal updated according the goolge event",
                              response.data
                            );
                            fetchGoals(userId);
                          })
                          .catch((error) => {
                            // Handle error response
                            console.error(
                              "Error updating goal according to google event:",
                              error
                            );
                          });
                      }
                    } else {
                      // delete daily goal
                      const url = `${SERVER}/api/goals/delete/`;
                      const deleteData = {
                        goal_id: dailyGoal[1].id,
                        user: userId,
                        hasGoogleCredentials: data.has_credentials,
                      };
                      axios
                        .post(url, deleteData)
                        .then((response) => {
                          // Handle success response
                          console.log("Goal deleted:", response.data);
                          fetchGoals(userId);
                        })
                        .catch((error) => {
                          // Handle error response
                          console.error("Error deleting goal:", error);
                        });
                    }
                  });
                }
                fetchGoals(userId);
              });
          } else {
            fetchGoals(userId);
          }
          setLoading(false);
        });
    })();

    (async () => {
      fetch(`${SERVER}/api/onboarded/?user=${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setOnboardingDialogOpen(!data.onboarded);
          setChecked(data.onboarded);
          console.log("Trying to set the onboarded value: ", data.onboarded);
          console.log("Data: ", data);
        })
        .catch((error) => {
          console.log("Error fetching onboarded status: ", error);
        });
    })();
  }, [userId, userEmail]);

  const handleGoogleIntegrationClick = () => {
    window.location = url;
  };

  const sortedGoalsData = (goalsData) => {
    if (goalsData.length > 0) {
      if (goalsData[0].goal_type === "YG") {
        return goalsData.sort((a, b) => {
          if (a.year < b.year) return -1;
          if (a.year > b.year) return 1;
          return 0;
        });
      } else if (goalsData[0].goal_type === "QG") {
        return goalsData.sort((a, b) => {
          if (a.quarter < b.quarter) return -1;
          if (a.quarter > b.quarter) return 1;
          return 0;
        });
      } else if (goalsData[0].goal_type === "MG") {
        return goalsData.sort((a, b) => {
          if (a.month < b.month) return -1;
          if (a.month > b.month) return 1;
          return 0;
        });
      } else if (goalsData[0].goal_type === "WG") {
        return goalsData.sort((a, b) => {
          if (a.week < b.week) return -1;
          if (a.week > b.week) return 1;
          return 0;
        });
      } else if (goalsData[0].goal_type === "DG") {
        return goalsData.sort((a, b) => {
          if (a.day < b.day) return -1;
          if (a.day > b.day) return 1;
          return 0;
        });
      }
    }
    return goalsData;
  };

  const renderGoals = (goalsData) => {
    let sortedGoals = sortedGoalsData(goalsData);

    return sortedGoals
      .filter((goal) => !goal.accomplished)
      .map((goalData) => (
        <Goal
          key={goalData.id}
          goalData={goalData}
          fetchGoals={fetchGoals}
          userId={userId}
          hasGoogleCredentials={hasGoogleCredentials}
          timezone={timezone}
          // Pass other relevant fields as props
        >
          {goalData.children && renderGoals(goalData.children)}
        </Goal>
      ));
  };

  const filteredGoals = goals.filter(
    (goal) => goal.goal_type === "MTP" && !goal.accomplished
  );

  const handleSavePurpose = () => {
    const newPurpose = {
      goal_data: {
        goal_text: purposeText,
        parent: null,
        goal_type: "MTP",
        user: userId,
      },
      hasGoogleCredentials: hasGoogleCredentials,
      timezone: timezone,
    };

    fetch(`${SERVER}/api/goals/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPurpose),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("New goal saved:", data);
        fetchGoals(userId);
        // Perform any necessary UI updates or navigate to a new page
      })
      .catch((error) => {
        console.error("Error saving goal:", error);
        // Handle any error notifications or UI updates
      });

    setPurposeText(
      "To help (your ideal customers) to (result they want to achieve) through (your solution)"
    );
    setIsDrawerOpen(false);
  };

  return (
    <div style={{}}>
      {/* <Button onClick={() => handleGoogleIntegrationClick()}>GCal</Button> */}
      {renderGoals(filteredGoals)}
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper>
        <Button
          onClick={toggleDrawer}
          startIcon={<AddIcon />}
          variant="outlined"
          style={{ margin: "20px 10px 20px 44px" }}
        >
          Add Purpose
        </Button>{" "}
        <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer}>
          <div
            style={{
              width: "100vw",
              padding: "10px",
            }}
          >
            <TextField
              label={"Massively Transformative Purpose"}
              value={purposeText}
              onChange={(e) => setPurposeText(e.target.value)}
              variant="outlined"
              margin="normal"
              multiline
              fullWidth
              onKeyDown={handleKeyDown}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="secondary"
                onClick={toggleDrawer}
                variant="outlined"
                style={{ margin: "10px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSavePurpose}
                style={{ margin: "10px" }}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </div>
        </Drawer>
      </Paper>
      <Dialog open={reauth} onClose={() => setReauth(false)}>
        <DialogTitle>Your Google Authenticion Has Expired</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={() => (window.location = url)}>
              Authenticate
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOnboardingDialogOpen}
        onClose={() => setOnboardingDialogOpen(false)}
      >
        <DialogTitle>Welcome To Clarity!</DialogTitle>
        <DialogContent>
          Here's how to get started:
          <ol>
            <li>
              Add a Massively Transformative Purpose to your Goal Stack. If
              you're unclear on your purpose, then navigate to the{" "}
              <Link to="/blueprint">blueprint</Link> to follow a
              neurscience-backed framework for finding it.
            </li>
            <li>
              Reverse engineer how you're going to serve your purpose by
              clicking the plus button on a goal to chunk it down into a smaller
              one.
            </li>
            <li>
              Set up a 2-way integration with Google Calendar so that the daily
              goals on your Goal Stack are synced with your calendar by clicking{" "}
              <a href={url}>here</a>. You can also set up the integration in the
              blueprint or FAQ pages.
            </li>
            <li>Take massive action and hit each of your goals!</li>
          </ol>
          <strong>IMPORTANT: </strong>You need to be added as a test user in
          order to be able to integrate with Google because Google is in the
          process of verifying this app. Email{" "}
          <a href="mailto:darren@useclarity.io">darren@useclarity.io</a> if you
          haven't been added as a test user yet.
          <br />
          <br />
          Visit the <Link to="/faq">FAQ</Link> if you have any questions.
          <br />
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleCheckboxClick} />
            }
            label="Never show this again."
          />
          <div>
            <Button
              variant="contained"
              onClick={() => setOnboardingDialogOpen(false)}
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default GoalStack;
