import React, { useState, useEffect } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getAllTimes } from "../lib/getTimes";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  IconButton,
  Button,
  TextField,
  Drawer,
  Typography,
  Card,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Tooltip,
  Modal,
  Icon,
  Fade,
  FormHelperText,
} from "@mui/material";
import {
  generateWeeks,
  generateWeekDays,
  generateMonths,
  getScheduleDisplay,
  getPaddingLeft,
  SERVER,
  get12PMDateObject,
  get1PMDateObject,
  checkForTimeError,
} from "../utils";
import axios from "axios";
import "./Goal.css";
import { styled } from "@mui/system";
import NavBar from "./NavBar";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Draw } from "@mui/icons-material";

const child_type = {
  MTP: "HHG",
  HHG: "YG",
  YG: "QG",
  QG: "MG",
  MG: "WG",
  WG: "DG",
  DG: "S",
  S: "SS",
};

const iconStyles = {
  // Define the regular styles
  // ...

  // Define the hover styles
  "&:hover": {
    backgroundColor: "lightgray", // Change the background color on hover
    cursor: "pointer", // Change the cursor to a pointer on hover
    borderRadius: "20%",
  },
};

const label_map = {
  MTP: "Massively Transformative Purpose",
  HHG: "High Hard Goal",
  YG: "Yearly Goal",
  QG: "Quarterly Goal",
  MG: "Monthly Goal",
  WG: "Weekly Goal",
  DG: "Daily Goal",
  S: "Step",
  SS: "Substep",
};

const child_input_label = {
  MTP: "High Hard Goal",
  HHG: "Yearly Goal",
  YG: "Quarterly Goal",
  QG: "Monthly Goal",
  MG: "Weekly Goal",
  WG: "Daily Goal",
  DG: "Step",
  S: "Substep",
};

const toDateObject = (dateString, timeString) => {
  const [year, month, day] = dateString.split("-");
  const [hours, minutes, seconds] = timeString.split(":");

  // Create a new Date object with the year, month, day, hours, minutes, and seconds
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

function Goal({
  goalData,
  children,
  fetchGoals,
  userId,
  hasGoogleCredentials,
  timezone,
}) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isToggleVisible, setIsToggleVisible] = useState(false);
  const [isStartClockOpen, setIsStartClockOpen] = useState(false);
  const [isEndClockOpen, setIsEndClockOpen] = useState(false);
  const [newChildGoalText, setNewChildGoalText] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAccomplishedDialogOpen, setAccomplishDialogOpen] = useState(false);
  const [notes, setNotes] = useState(goalData.notes ? goalData.notes : "");
  const [childNotes, setChildNotes] = useState("");
  const [timeError, setTimeError] = useState(false);
  const [dayError, setDayError] = useState(false);
  const [weekError, setWeekError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [quarterError, setQuarterError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [hasUnaccomplishedChildren, setHasUnaccomplishedChildren] =
    useState(false);
  const [goalText, setGoalText] = useState(goalData.goal_text);
  const [goalAccomplished, setGoalAccomplished] = useState(
    goalData.accomplished
  );
  const [selectedYear, setSelectedYear] = useState(
    goalData.year ? goalData.year : ""
  );
  const [selectedQuarter, setSelectedQuarter] = useState(
    goalData.quarter ? goalData.quarter : ""
  );
  const [selectedMonth, setSelectedMonth] = useState(
    goalData.month ? goalData.month : ""
  );
  const [selectedWeek, setSelectedWeek] = useState(
    goalData.week ? goalData.week : ""
  );
  const [selectedDay, setSelectedDay] = useState(
    goalData.day ? goalData.day : ""
  );
  const [selectedStartTime, setSelectedStartTime] = useState(
    goalData.time_start ? toDateObject(goalData.day, goalData.time_start) : null
  );
  const [selectedEndTime, setSelectedEndTime] = useState(
    goalData.time_end ? toDateObject(goalData.day, goalData.time_end) : null
  );

  const fetchHasUnaccomplishedDescendants = (goalId) => {
    const url = `${SERVER}/api/goals/${goalId}/has-unaccomplished-descendants/`;

    return axios
      .get(url)
      .then((response) => {
        return setHasUnaccomplishedChildren(
          response.data.has_unaccomplished_descendants
        );
      })
      .catch((error) => {
        console.error(
          "Error fetching goal has unaccomplished descendants:",
          error
        );
        // Handle error
      });
  };

  const getLabelColor = () => {
    if (goalData.goal_type === "MTP") return "indigo";
    else if (goalData.goal_type === "HHG") return "red";
    else if (goalData.goal_type === "YG") return "blue";
    else if (goalData.goal_type === "QG") return "green";
    else if (goalData.goal_type === "MG") return "orange";
    else if (goalData.goal_type === "WG") return "violet";
    else if (goalData.goal_type === "DG") return "brown";
    else return "gray";
  };

  useEffect(() => {
    fetchHasUnaccomplishedDescendants(goalData.id);
    if (goalData.children && goalData.children.length !== 0) {
      setIsToggleVisible(true);
    }
  }, [goalData]);

  const currentYear = new Date().getFullYear();
  let yearList = Array.from({ length: 5 }, (_, index) => currentYear + index);
  let monthList = [];
  if (goalData.goal_type === "QG" || goalData.goal_type === "MG") {
    monthList = generateMonths(goalData.quarter);
  }
  let weekList = [];
  if (goalData.goal_type === "MG" || goalData.goal_type === "WG") {
    weekList = generateWeeks(goalData.month, goalData.year);
  }
  let dayList = [];
  if (goalData.goal_type === "WG" || goalData.goal_type === "DG") {
    dayList = generateWeekDays(goalData.week, goalData.year);
  }

  const handleToggle = () => setIsExpanded(!isExpanded);
  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);
  const handleAccomplishDialogOpen = () => {
    console.log(`has unaccomplished children: ${hasUnaccomplishedChildren}`);
    setAccomplishDialogOpen(true);
  };
  const handleAccomplishDialogClose = () => setAccomplishDialogOpen(false);

  const handleAddChildGoal = () => {
    setIsExpanded(true);
    updateSelected();
    setIsDrawerOpen(true);
  };

  const handleGoalAccomplished = () => {
    setGoalAccomplished(true);
    setTimeout(() => {
      setGoalAccomplished(false);
    }, 1000);
    handleAccomplishGoal();
  };

  const handleOpenUpdateDrawer = () => {
    updateSelected();
    setIsUpdating(true);
    setIsDrawerOpen(true);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleStartClockToggle = (event) => {
    setIsEndClockOpen(false);
    setIsStartClockOpen(!isStartClockOpen);
  };

  const handleEndClockToggle = (event) => {
    setIsStartClockOpen(false);
    setIsEndClockOpen(!isEndClockOpen);
  };

  const handleQuarterChange = (event) => {
    setSelectedQuarter(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const updateSelected = () => {
    setGoalText(goalData.goal_text);
    setGoalAccomplished(goalData.accomplished);
    setSelectedDay(goalData.day ? goalData.day : "");
    setSelectedWeek(goalData.week ? goalData.week : "");
    setSelectedMonth(goalData.month ? goalData.month : "");
    setSelectedQuarter(goalData.quarter ? goalData.quarter : "");
    setSelectedYear(goalData.year ? goalData.year : "");
    setNotes(goalData.notes ? goalData.notes : "");
    setTimeError(false);
    setDayError(false);
    setWeekError(false);
    setMonthError(false);
    setQuarterError(false);
    setYearError(false);
    setSelectedStartTime(
      goalData.time_start
        ? toDateObject(goalData.day, goalData.time_start)
        : null
    );
    setSelectedEndTime(
      goalData.time_end ? toDateObject(goalData.day, goalData.time_end) : null
    );
    yearList = Array.from({ length: 5 }, (_, index) => currentYear + index);
    if (goalData.goal_type === "QG" || goalData.goal_type === "MG") {
      monthList = generateMonths(goalData.quarter);
    }
    if (goalData.goal_type === "MG" || goalData.goal_type === "WG") {
      weekList = generateWeeks(goalData.month, goalData.year);
    }
    if (goalData.goal_type === "WG" || goalData.goal_type === "DG") {
      dayList = generateWeekDays(goalData.week, goalData.year);
    }
  };

  const handleDrawerClose = () => {
    updateSelected();
    setIsUpdating(false);
    setIsDrawerOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isDrawerOpen && !isUpdating) {
      handleSaveChildGoal();
    }
    if (event.key === "Enter" && isDrawerOpen && isUpdating) {
      handleUpdateGoal();
    }
  };

  const handleDeleteGoal = () => {
    const url = `${SERVER}/api/goals/delete/`;
    const data = {
      goal_id: goalData.id,
      user: userId,
      hasGoogleCredentials: hasGoogleCredentials,
    };
    axios
      .post(url, data)
      .then((response) => {
        // Handle success response
        console.log("Goal deleted:", response.data);
        fetchGoals(userId);
      })
      .catch((error) => {
        // Handle error response
        console.error("Error deleting goal:", error);
      });
    setIsDeleteDialogOpen(false);
  };

  const handleAccomplishGoal = () => {
    const updateData = {
      goal_id: goalData.id,
      user: userId,
    };
    console.log("update data", updateData);

    const url = `${SERVER}/api/goals/accomplished/`;
    axios
      .post(url, updateData)
      .then((response) => {
        console.log(
          "Set goal and its childrens' completed value to true: ",
          response.data
        );
        fetchGoals(userId);
      })
      .catch((error) => {
        console.error(
          "Error setting goal and its children to completed: ",
          error
        );
      });
    setAccomplishDialogOpen(false);
  };

  const handleUpdateGoal = () => {
    if (checkForTimeError(selectedStartTime, selectedEndTime)) {
      setTimeError(true);
      return;
    }

    if (goalData.goal_type === "DG" && selectedDay === "") {
      setDayError(true);
      return;
    }
    if (goalData.goal_type === "WG" && selectedWeek === "") {
      setWeekError(true);
      return;
    }
    if (goalData.goal_type === "MG" && selectedMonth === "") {
      setMonthError(true);
      return;
    }
    if (goalData.goal_type === "QG" && selectedQuarter === "") {
      setQuarterError(true);
      return;
    }
    if (goalData.goal_type === "YG" && selectedYear === "") {
      setYearError(true);
      return;
    }

    const updateData = {
      goal_data: {
        goal_id: goalData.id,
        goal_text: goalText,
        parent: goalData.parent,
        year: selectedYear === "" ? null : selectedYear,
        quarter: selectedQuarter === "" ? null : selectedQuarter,
        month: selectedMonth === "" ? null : selectedMonth,
        week: selectedWeek === "" ? null : selectedWeek,
        day: selectedDay === "" ? null : selectedDay,
        time_start: selectedStartTime
          ? getAllTimes(selectedStartTime).time
          : null,
        time_end: selectedEndTime ? getAllTimes(selectedEndTime).time : null,
        goal_type: goalData.goal_type,
        notes: notes,
        user: userId,
      },
      hasGoogleCredentials: hasGoogleCredentials,
      timezone: timezone,
    };
    const url = `${SERVER}/api/goals/update/`;
    axios
      .post(url, updateData)
      .then((response) => {
        //Handle success response
        console.log("Goal updated:", response.data);
        fetchGoals(userId);
      })
      .catch((error) => {
        // Handle error response
        console.error("Error updating goal:", error);
      });
    setIsDrawerOpen(false);
    handleDrawerClose();
  };

  const handleSaveChildGoal = () => {
    // Save the new child goal using an API call or other logic
    const newGoal = {
      goal_data: {
        goal_text: newChildGoalText,
        parent: goalData.id,
        year: selectedYear === "" ? null : selectedYear,
        quarter: selectedQuarter === "" ? null : selectedQuarter,
        month: selectedMonth === "" ? null : selectedMonth,
        week: selectedWeek === "" ? null : selectedWeek,
        day: selectedDay === "" ? null : selectedDay,
        time_start: selectedStartTime
          ? getAllTimes(selectedStartTime).time
          : null,
        time_end: selectedEndTime ? getAllTimes(selectedEndTime).time : null,
        goal_type: child_type[goalData.goal_type],
        notes: childNotes,
        user: userId,
      },
      hasGoogleCredentials: hasGoogleCredentials,
      timezone: timezone,
    };

    if (checkForTimeError(selectedStartTime, selectedEndTime)) {
      setTimeError(true);
      return;
    }
    if (child_type[goalData.goal_type] === "DG" && selectedDay === "") {
      setDayError(true);
      return;
    }
    if (child_type[goalData.goal_type] === "WG" && selectedWeek === "") {
      setWeekError(true);
      return;
    }
    if (child_type[goalData.goal_type] === "MG" && selectedMonth === "") {
      setMonthError(true);
      return;
    }
    if (child_type[goalData.goal_type] === "QG" && selectedQuarter === "") {
      setQuarterError(true);
      return;
    }
    if (child_type[goalData.goal_type] === "YG" && selectedYear === "") {
      setYearError(true);
      return;
    }

    fetch(`${SERVER}/api/goals/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newGoal),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("New goal saved:", data);
        fetchGoals(userId);
        // Perform any necessary UI updates or navigate to a new page
      })
      .catch((error) => {
        console.error("Error saving goal:", error);
        // Handle any error notifications or UI updates
      });

    // Clear the input field and close the drawer
    setNewChildGoalText("");
    setChildNotes("");
    setIsDrawerOpen(false);
    handleDrawerClose();
  };

  let indent = getPaddingLeft(goalData.goal_type);
  let due_date = getScheduleDisplay(goalData);
  return (
    <div className={`tree`}>
      <Paper
        style={{ display: "flex", alignItems: "center", width: "100%" }}
        className={`tree-label ${isExpanded ? "expanded" : "collapsed"}`}
        elevation={0}
      >
        <div className="label-container" style={{ marginLeft: `${indent}px` }}>
          {isExpanded ? (
            <IconButton
              onClick={handleToggle}
              style={{
                margin: "5px 0px 5px 5px",
                padding: "5px",
                visibility: isToggleVisible ? "visible" : "hidden",
              }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleToggle}
              style={{
                margin: "5px 0px 5px 5px",
                padding: "5px",
                visibility: isToggleVisible ? "visible" : "hidden",
              }}
            >
              <ArrowRightIcon />
            </IconButton>
          )}
        </div>
        <div style={{ padding: "5px", flexGrow: "1" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                onClick={handleOpenUpdateDrawer}
                style={{ flexGrow: "1", cursor: "pointer" }}
                variant="body1"
              >
                {goalData.goal_text}
              </Typography>
              {/* <DeleteIcon sx={iconStyles} onClick={handleDeleteDialogOpen} /> */}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "10px",
                  color: getLabelColor(),
                }}
                variant="caption"
              >
                {`${label_map[goalData.goal_type]} ${due_date}`}
              </Typography>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Tooltip title={`Mark as finished`}>
            <IconButton
              onClick={
                !hasUnaccomplishedChildren
                  ? handleGoalAccomplished
                  : handleAccomplishDialogOpen
              }
              style={{ margin: "5px", padding: "5px" }}
            >
              {goalAccomplished ? (
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              ) : (
                <RadioButtonUncheckedOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip
            title={`Chunk down by creating a ${
              child_input_label[goalData.goal_type]
            }`}
          >
            <IconButton
              disabled={goalData.goal_type === "DG"}
              onClick={handleAddChildGoal}
              style={{ margin: "5px", padding: "5px" }}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Paper>
      <Divider />
      {isExpanded && <div className="tree-children">{children}</div>}
      <Dialog
        open={isAccomplishedDialogOpen}
        onClose={handleAccomplishDialogClose}
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <p>
            Are you sure you want to mark this goal and all its descendants as
            completed?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAccomplishDialogClose}>No</Button>
          <Button onClick={handleAccomplishGoal} variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this goal and its descendants?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button onClick={handleDeleteGoal} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer open={isDrawerOpen} onClose={handleDrawerClose} anchor="bottom">
        <div
          style={{
            minWidth: "350px",
            padding: "20px 10px",
          }}
        >
          <TextField
            label={
              isUpdating
                ? `Update ${label_map[goalData.goal_type]}`
                : `New ${child_input_label[goalData.goal_type]} for "${
                    goalData.goal_text
                  }"`
            }
            value={isUpdating ? goalText : newChildGoalText}
            onChange={
              isUpdating
                ? (e) => setGoalText(e.target.value)
                : (e) => setNewChildGoalText(e.target.value)
            }
            variant="outlined"
            fullWidth
            onKeyDown={handleKeyDown}
            multiline
          />
        </div>
        {((isUpdating && goalData.goal_type === "YG") ||
          (goalData.goal_type === "HHG" && !isUpdating)) && (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-year-label">Year</InputLabel>
              <Select
                labelId="select-year-label"
                id="select-year"
                value={selectedYear}
                label="Year"
                onChange={handleYearChange}
              >
                <MenuItem value={yearList[0]}>{yearList[0]}</MenuItem>
                <MenuItem value={yearList[1]}>{yearList[1]}</MenuItem>
                <MenuItem value={yearList[2]}>{yearList[2]}</MenuItem>
                <MenuItem value={yearList[3]}>{yearList[3]}</MenuItem>
                <MenuItem value={yearList[4]}>{yearList[4]}</MenuItem>
              </Select>
              {yearError && (
                <FormHelperText style={{ color: "red" }}>
                  Error: Must select a Year
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        {((isUpdating && goalData.goal_type === "QG") ||
          (goalData.goal_type === "YG" && !isUpdating)) && (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-quarter-label">Quarter</InputLabel>
              <Select
                labelId="select-quarter-label"
                id="select-quarter"
                value={selectedQuarter}
                label="Quarter"
                onChange={handleQuarterChange}
              >
                <MenuItem value={1}>{"Q1"}</MenuItem>
                <MenuItem value={2}>{"Q2"}</MenuItem>
                <MenuItem value={3}>{"Q3"}</MenuItem>
                <MenuItem value={4}>{"Q4"}</MenuItem>
              </Select>
              {quarterError && (
                <FormHelperText style={{ color: "red" }}>
                  Error: Must select a Quarter
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        {((isUpdating && goalData.goal_type === "MG") ||
          (goalData.goal_type === "QG" && !isUpdating)) && (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-monthly-label">Month</InputLabel>
              <Select
                labelId="select-monthly-label"
                id="select-month"
                value={selectedMonth}
                label="Month"
                onChange={handleMonthChange}
              >
                <MenuItem value={1 + (goalData.quarter - 1) * 3}>
                  {monthList[0]}
                </MenuItem>
                <MenuItem value={2 + (goalData.quarter - 1) * 3}>
                  {monthList[1]}
                </MenuItem>
                <MenuItem value={3 + (goalData.quarter - 1) * 3}>
                  {monthList[2]}
                </MenuItem>
              </Select>
              {monthError && (
                <FormHelperText style={{ color: "red" }}>
                  Error: Must select a Month
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        {((isUpdating && goalData.goal_type === "WG") ||
          (goalData.goal_type === "MG" && !isUpdating)) && (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="select-week-label">Week</InputLabel>
              <Select
                labelId="select-week-label"
                id="select-week"
                value={selectedWeek}
                label="Week"
                onChange={handleWeekChange}
              >
                {weekList.map((week) => (
                  <MenuItem key={week.label} value={week.number}>
                    {week.label}
                  </MenuItem>
                ))}
              </Select>
              {weekError && (
                <FormHelperText style={{ color: "red" }}>
                  Error: Must select a Week
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        {((isUpdating && goalData.goal_type === "DG") ||
          (goalData.goal_type === "WG" && !isUpdating)) && (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            <FormControl fullWidth>
              <div style={{ display: "flex" }}>
                <InputLabel id="select-day-label">Date</InputLabel>
                <Select
                  labelId="select-day-label"
                  id="select-day"
                  value={selectedDay}
                  label="Day"
                  onChange={handleDayChange}
                  style={{ marginRight: "10px", minWidth: "100px" }}
                >
                  {dayList.map((day) => (
                    <MenuItem key={day.label} value={day.value}>
                      {day.label}
                    </MenuItem>
                  ))}
                </Select>
                <div style={{ marginRight: "10px" }}>
                  <TimePicker
                    label="Start Time"
                    value={selectedStartTime}
                    onChange={(newValue) => setSelectedStartTime(newValue)}
                  />
                </div>
                <div style={{ marginRight: "10px" }}>
                  <TimePicker
                    label="End Time"
                    value={selectedEndTime}
                    onChange={(newValue) => setSelectedEndTime(newValue)}
                  />
                </div>
              </div>
              {timeError && (
                <FormHelperText style={{ color: "red" }}>
                  Error: End Time must be after Start Time
                </FormHelperText>
              )}
              {dayError && (
                <FormHelperText style={{ color: "red" }}>
                  Error: Must select a Date
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        <TextField
          id="clear-goals-textfield"
          multiline
          minRows={4}
          label="Notes"
          value={isUpdating ? notes : childNotes}
          style={{ margin: "0px 10px 10px 10px" }}
          onChange={ isUpdating ? (e) => setNotes(e.target.value) : (e) => setChildNotes(e.target.value)}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleDeleteDialogOpen} style={{margin: "0px 10px 10px 0px"}}>
            <DeleteIcon />
          </IconButton>
          <div style={{margin: "0px 10px 10px 0px"}}>
            <Button
              variant="outlined"
              onClick={handleDrawerClose}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
          <div style={{margin: "0px 10px 10px 0px"}}>
            <Button
              variant="contained"
              onClick={isUpdating ? handleUpdateGoal : handleSaveChildGoal}
            >
              {isUpdating ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Goal;
