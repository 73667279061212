import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Divider, Typography, Collapse } from "@mui/material";
import "../styles/salesPage.css";

const TermsOfUse = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    navigate("/login", {
      replace: true,
      state: { from: location },
    });
  };

  const goHome = (event) => {
    navigate("/", {
      replace: true,
      state: { from: location },
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px 5%",
        }}
      >
        <h3
          onClick={(event) => goHome(event)}
          style={{ color: "black", cursor: "pointer" }}
        >
          Clarity
        </h3>
        <Button
          style={{ maxHeight: "40px", minHeight: "35px" }}
          variant="outlined"
          onClick={(event) => handleClick(event)}
        >
          Login
        </Button>
      </div>
      <Divider />
      <div
        className="section"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "100px 5%",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            marginBottom: "8px",
            color: "rgba(118, 126, 173, 0.59)",
            fontSize: "15px",
            fontWeight: "700",
            letterSpacing: "1px",
          }}
        >
          Terms of Service
        </div>
        <h1
          style={{
            maxWidth: "720px",
            marginTop: "0px",
            marginBottom: "30px",
            color: "#333",
            fontSize: "42px",
            lineHeight: "51px",
            textAlign: "center",
          }}
        >
          Terms of service
        </h1>
        <h4 style={{ marginBottom: "30px" }}>Introduction</h4>
        <p style={{ marginBottom: "60px" }}>
          Thank you for using our website and the product useclarity.io. Your
          use of our website and the product useclarity.io is subject to these
          Terms of Service. To use the website and the product, you need to
          agree to all the statements below, with no objections or modifications
          to the terms and conditions or regarding any of the operating rules
          and policies. Please read this agreement carefully before you access
          or proceed to use the website and/or the product useclarity.io. If
          you do not agree with all the terms and conditions of useclarity.io,
          you may not access or use the website and the product.
        </p>
        <h4 style={{ marginBottom: "30px" }}>The product</h4>
        <p style={{ marginBottom: "60px" }}>
          useclarity.io allows you to easily create a campaign to send personalised messages to your ideal clients on Twitter. Using the
          product, you can generate high quality leads on
          autopilot. We are constantly changing and improving the product. We
          may make modifications or changes such as adding or removing features
          and functionalities.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Subscriptions</h4>
        <p style={{ marginBottom: "60px" }}>
          To use the product, you are required to have a subscription to the
          product. The subscription period is monthly. After the first month,
          the subscription will continue on a monthly basis until the
          subscription is cancelled by you or us. It can be cancelled at any
          given moment and can be renewed immediately after.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Authorisation</h4>
        <p style={{ marginBottom: "60px" }}>
          If you are subscribing on behalf of a company or organisation, you
          confirm to us that you have authority to act on behalf of that entity,
          and that entity accepts our terms. If you use the product as a private
          person, you are required and you declare to be at least 18 years old
          and have legal capacity to enter the into this contract.
        </p>
        <h4 style={{ marginBottom: "30px" }}>
          Fees, recurring billing and cancellation
        </h4>
        <p style={{ marginBottom: "60px" }}>
          The product useclarity.io is billed on a monthly subscription basis.
          The customer should provide accurate and complete billing information.
          Once provided with the billing information, useclarity.io is
          authorised to and will automatically collect the fee for the
          subscription every month. The payment is collected by direct debit at
          the time of subscribing and after the first month on the same date
          every following month. Your subscription fee will be charged to you
          each month until you cancel your subscription. The subscription fees
          are fully earned upon payment and are not subject to any refund,
          credit or offset, pro-rata or otherwise, upon cancellation.
          Clarity may change the amount of the subscription fee at any time.
          You will be informed in advance of a price change, and you will be
          given the choice to cancel your subscription or continue using the
          product with the new price of the subscription. We reserve the right
          to take any lawful action in response to any past-due subscription
          fees, including; cancelling your subscription or otherwise suspending
          your access to the product until all past-due amounts are paid. Please
          note that cancellation or suspension of your subscription will not
          relieve you of your obligation to pay any past-due or outstanding
          amounts.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Use of the product</h4>
        <p style={{ marginBottom: "60px" }}>
          We highly suggest you read the terms and privacy policy of Twitter and
          act in accordance with these terms. You may use the product, as long
          as you comply with these Terms of Service, the law and the terms of
          service of Twitter. Insofar as not specified therein, you may not post
          any content that, inter alia: infringes on the rights of any third
          party; is offensive, threatening, unlawful, deceptive, inaccurate, or
          harmful; includes personal or confidential information belonging to
          others; includes spam; targets minors; impersonates or misrepresents
          your affiliation with another person or entity. We reserve the right
          to suspend or terminate subscriptions or block users that violate
          these Terms of Service, the law or the terms of service of twitter.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Disclaimer</h4>
        <p style={{ marginBottom: "60px" }}>
          Clarity is not affiliated with Twitter in any way. Twitter may
          block or restrict your Twitter profile based on activities you do with
          or without Clarity. Clarity has no control over, and assumes
          no responsibility for, this. Clarity disclaims all warranties and
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement. By signing up for Clarity and linking your
          Twitter account you acknowledge this risk and therefore Clarity is
          not responsible or accountable for any blocks or restrictions. The use
          of Clarity is therefore solely a risk taken by the user. Clarity does not take responsibility
          for the product being interrupted or unavailable at times. Clarity
          will inform its users in case of maintenance of the website and/or the
          product unless it is an emergency.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Limitation of liability</h4>
        <p style={{ marginBottom: "60px" }}>
          Clarity will not be liable to you for any consequential, special,
          punitive, indirect, or incidental damages or lost profits or revenues,
          whether incurred directly or indirectly, or any loss of data, use,
          goodwill, or other intangible losses, relating to, arising out of, or
          in any way in connection with our terms, us, or our product. Your only
          remedy with respect to any breach by us of any term in these Terms of
          Service, our privacy policy, violation of any right or law, or
          dissatisfaction with the product, is to terminate your subscription
          and discontinue using the product. Notwithstanding the foregoing, in
          any event our aggregate liability relating to, arising out of, or in
          any way in connection with our terms, us, or our product will not
          exceed the greater of one hundred euro (€ 100) or the amount you have
          paid us in the past twelve months. The foregoing disclaimer and the
          limitation of liability will apply to the maximum extent permitted by
          applicable law.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Indemnification</h4>
        <p style={{ marginBottom: "60px" }}>
          If anyone brings a claim against us related to your actions,
          information, content or any other use of our product by you, you will,
          to the maximum extent permitted by applicable law, indemnify, and hold
          Clarity harmless from and against all liabilities, damages,
          losses, and expenses of any kind (including reasonable legal fees and
          costs) relating to, arising out of, or in any way in connection with
          your use of the product, your breach of our Terms of Service or
          applicable law or any misrepresentation made by you.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Changes</h4>
        <p style={{ marginBottom: "60px" }}>
          Clarity reserves the right to modify and/or replace the Terms and
          Conditions at any given moment. Clarity will provide details to
          its users about any changes in the terms and policies of Clarity.
          Once the changes become functional, the users must agree with the
          changes. If not, the users must discontinue to use the product
          Clarity.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Severance</h4>
        <p style={{ marginBottom: "60px" }}>
          If it turns out that a particular term of these Terms of Service is
          not enforceable for any reason, this will not affect any other terms.
        </p>
        <h4 style={{ marginBottom: "30px" }}>Governing law</h4>
        <p style={{ marginBottom: "60px" }}>
          These Terms and Conditions and any agreement regarding the product
          shall be subject to and construed in accordance with the laws of the
          United States. Competent courts in the United States shall have exclusive jurisdiction in the event of any
          dispute between the parties, without prejudice to Clarity's right
          to submit any dispute to the court in your place of registration.
        </p>
      </div>
      <div className="section" style={{ padding: "0px 5% 100px 5%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <h2
              style={{
                padding: "10px 20px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              Clarity
            </h2>
          </div>
          <div style={{ margin: "25px 0px" }}>
            Feel free to contact us.
            <br />
            <a href="mailto:darren@useclarity.io">darren@useclarity.io</a>
          </div>
          <Link to="/terms" style={{ textDecoration: "none", color: "black" }}>
            Terms of use
          </Link>
          <Link
            to="/privacy"
            style={{ textDecoration: "none", color: "black" }}
          >
            Privacy Policy
          </Link>
          <div style={{ marginTop: "25px" }}>
            ©2023 Clarity. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;