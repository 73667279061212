import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { SERVER } from "../utils";
import { useAuthenticator } from "@aws-amplify/ui-react";

const FAQ = () => {
  const { user } = useAuthenticator();
  const userEmail = user.attributes.email;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let url = `${SERVER}/google/authorize/?email=${userEmail}&timezone=${timezone}`;

  // const handleGoogleIntegrationClick = () => {
  //   let url = `${SERVER}/google/authorize/?email=${userEmail}&timezone=${timezone}`;
  //   window.location = url;
  // };

  return (
    <NavBar title="Frequently Asked Questions">
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What is a Massively Transformative Purpose?
          </h4>
          <p style={{ marginTop: "0px" }}>
            A Massively Transformative Purpose is a one sentence statement to solve some massive problem in the world that you're passionate about so that you can have clarity on your direction in life.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What is a good market?
          </h4>
          <p style={{ marginTop: "0px" }}>
            A good market has four traits:
            <ol>
              <li>Massive Pain</li>
              <li>Easy to target</li>
              <li>Purchasing Power</li>
              <li>Growing</li>
            </ol>
            It's important to consider the market when considering a Massively Transformtive Purpose to commit to so that you can set yourself up for business success.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What is a Goal Stack?
          </h4>
          <p style={{ marginTop: "0px" }}>
            A Goal Stack is a structure for reverse-engineering how to serve your Massively Transformative Purpose. 
            At the top of the stack is your Massively Transformative Purpose, then High Hard Goals that are so audaciously big that they will take you at least 1 to 5 years to accomplish, 
            then you continue to reverse engineer those goals into yearly, quarterly, monthly, weekly, daily, and clear goals.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What are clear goals and why are they important?
          </h4>
          <p style={{ marginTop: "0px" }}>
            Clear goals are steps and sub-steps to accomplish your daily goals. They're important because procrastination is caused by uncertainty. 
            When you're uncertain on how to accomplish your daily goals, then you can easily get stuck in paralysis by analysis. <br/><br/>
            If you set clear goals in your power down ritual the night before, then you can wake up and start executing without thinking about how to get started; 
            therefore, reducing friction, eliminating procrastination, and improving productivity. 
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What are S.M.A.R.T goals?
          </h4>
          <p style={{ marginTop: "0px" }}>
            Smart goals are specific, measurable, achievable, relevant, and time-based.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            How do I set up a 2-way integration between my Goal Stack and Google Calendar?
          </h4>
          <p style={{ marginTop: "0px" }}>
            Click <a href={url}>here</a> to set up a 2-way integration between your Goal Stack and Google Calendar. <br/><br/>This will make it so that your daily goals
            are created as events on your calendar and updates are synced.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What is intrinsic and extrinsic motivation?
          </h4>
          <p style={{ marginTop: "0px" }}>
            Extrinsic motivation comes from things like money, fame, sex, and status. It's great for getting you started on a journey, but it's not good at keeping you going because it decreases over time. Which means you'll eventually 
            feel unmotivated. Which really means you'll be at a high risk for failure because the number one reason people fail in any adventure is because they quit from lack of motivation. <br/><br/>

            Intrinsic motivation comes from curiosity, passion, purpose, mastery, and autonomy. Serving a purpose that allows you to do work you're passionate about will lead to the desire for mastery and autonomy. 
            Which means you'll align your instrinsic movtivational stack, which really means you'll enter a flow-fueled passion cycle where work feels like play and you'll build unstoppable momentum.
            This is because passion makes it easy to focus andfocused attention leads to flow states and flow states create more passion.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            How can I apply for results coaching?
          </h4>
          <p style={{ marginTop: "0px" }}>
            You can apply for results coaching by filling out the form <a href="https://calendar.app.google/49GaRK18E6ovUXFf9" target="_blank" rel="noopener noreferrer">here</a> and scheduling an application call so that we can see if we're a good fit for each other. <br/><br/>

            This coaching program is for people who want to start up or scale a passion-driven business and need coaching and accountability to get results faster.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            How can I give feedback or contact support?
          </h4>
          <p style={{ marginTop: "0px" }}>
            You can give feedback or contact support by emailing{" "}
            <a href="mailto:darren@useclarity.io">darren@useclarity.io</a>
          </p>
        </div>
      </Paper>
    </NavBar>
  );
};

export default FAQ;