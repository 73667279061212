import { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { Button, Divider } from "@mui/material";
import "@aws-amplify/ui-react/styles.css";

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const handleClick = (event) => {
    navigate("/login", {
      replace: true,
      state: { from: location },
    });
  };

  const goHome = (event) => {
    navigate("/", {
      replace: true,
      state: { from: location },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        className="section"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px 5%",
          flexGrow: "1"
        }}
      >
        <h1 style={{ marginBottom: "15px" }}>Welcome to Clarity</h1>
        <h4 style={{ color: "#8a94a6", marginTop: "0px" }}>
          Log in with your account to continue
        </h4>
        <View className="auth-wrapper">
          <Authenticator loginMechanisms={["email"]} />
        </View>
      </div>
      <div
        className="section"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px 5% 10px 5%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link
              to="/terms"
              style={{ textDecoration: "none", color: "black" }}
            >
              Terms of use
            </Link>
            <Divider
              style={{
                border: "1px solid gray",
                height: "15px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
              orientation="vertical"
            />
            <Link
              to="/privacy"
              style={{ textDecoration: "none", color: "black" }}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
