import { SERVER } from "../utils";
import { startOfWeek, endOfWeek, format, addDays, getISOWeek, getYear } from 'date-fns';



const fetchGoals = async (userId) => {
    try {
      const response = await fetch(`${SERVER}/api/goals/?userId=${userId}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

export const getGoalType = async (type, userId) =>{
    try {
        const goals = await fetchGoals(userId)
        const daily_goals = Object.entries(goals).filter(([key, value]) =>value.goal_type===type)
        return daily_goals;
    } catch (error) {
        console.error(error);
  }
}

export const getGoalbyG_ID = async (id, userId) =>{
  try {
      const goals = await fetchGoals(userId);
      const daily_goal = Object.entries(goals).filter(([key,value])=>value.goal_type==="DG" && value.google_event_id===id)
      return daily_goal;
  } catch (error) {
      console.error(error);
}
}

function getWeekNumberAndYear(dateString) {

  let date = new Date(dateString);

  console.log("DATE", date)
  const weekEnd = endOfWeek(date, { weekStartsOn: 0 });
  const weekNo = getISOWeek(weekEnd);

  console.log("WEEK NUMBER", weekNo)
  
  return [weekNo, date.getFullYear()];
}

// so it's passing in the time right now so that it gets the weekly goals
// for this current week because that's the view that load when the component mounts
export const getWeeklyGoals = async (daily_goal_date, userId) =>{
  try {
      console.log("getWeeklyGoals() - ", daily_goal_date)
      const goals = await fetchGoals(userId)

      // what is getWeekNumberAndYear doing? It's being passed the current time
      let [weekNumber, year] = getWeekNumberAndYear(daily_goal_date);

      console.log(`weekNumber: ${weekNumber}\nyear: ${year}`)

      const weekly_goals = Object.entries(goals).filter(([key, value]) =>value.goal_type==="WG"&&value.week === weekNumber && value.year === year)
      return weekly_goals;
  } catch (error) {
      console.error(error);
}
}

export const findGoal = async (id, userId) =>{
  try {
      const goals = await fetchGoals(userId)
      const goal = Object.entries(goals).filter(([key, value]) =>value.id===id)
      return goal;
  } catch (error) {
      console.error(error);
  }
}

function sortClearGoalsByOrder(goals) {
  return goals.sort((a, b) => a.order - b.order);
}

export const fetchClearGoals = async (userId, dailyGoalID) => {
  try {
    const response = await fetch(`${SERVER}/api/goals/getcleargoals/?userId=${userId}&dailyGoalId=${dailyGoalID}&`);
    const data = await response.json();

    const sorteddata = sortClearGoalsByOrder(data);
    
    return sorteddata;
  } catch (error) {
    console.error(error);
  }
};
